import { Component, Input } from '@angular/core';
import { MapService } from '@services/map/map.service';
import { TranslationPathConstants } from '@utils/constants/translation-path.constants';
import { IVehicleData } from '@utils/interfaces/key-readout/vehicle-data/vehicle-data.interface';
import { IVehicleDataGroup } from '@utils/interfaces/key-readout/vehicle-data-group.interface';
import { IServiceKeyData } from '@utils/interfaces/key-readout/service-key-data/service-key-data.interface';

/**
 * This component cointains information regarding the vehicle data
 */
@Component({
  selector: 'app-vehicle-data',
  templateUrl: './vehicle-data.component.html'
})
export class VehicleDataComponent {
  // vehicle data information
  @Input() groupServiceKey?: IVehicleDataGroup;


  // path and name of the card icon
  readonly iconPath = 'assets/icons/key-data/car-24.svg';
  // data path, which is needed to map the translation from translation JSON
  readonly translationPath = TranslationPathConstants.vehicleData;

  /**
   * Creates an instance of VehicleDataComponent.
   *
   * @param mapService map the fieldIds into a form, readable by the TranslationModule
   */
  constructor(
    public mapService: MapService

    ) { }
}
