<app-icon-card [icon]="iconPath" [addInfoText]="addInfoText" *ngIf="displayData$ | async">
  <!-- first col (mileage) -->
  <groupui-grid-row>
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- mileage -->
      <app-description-value-cell [fieldName]="mapService.mapFieldIdToPath(mileage?.fieldId, translationPath)"
        [value]="mileage?.value" [unit]="mileage?.unit">
      </app-description-value-cell>
    </groupui-grid-col>
  </groupui-grid-row>

  <!-- first col (inspection information) -->
  <groupui-grid-row class="content-container">
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- inspection distance -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.inspectionDistance?.fieldId, translationPath)"
        [value]="serviceInterval?.inspectionDistance?.value" [unit]="serviceInterval?.inspectionDistance?.unit">
      </app-description-value-cell>
      <!-- inspection time -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.inspectionTime?.fieldId, translationPath)"
        [value]="serviceInterval?.inspectionTime?.value" [unit]="serviceInterval?.inspectionTime?.unit">
      </app-description-value-cell>
    </groupui-grid-col> 

    <!-- whitespace -->
    <groupui-grid-col class="value hideL">
      &nbsp;
    </groupui-grid-col>

    <!-- second col (oil information) -->
    <groupui-grid-col l="6" m="12" s="12" class="unsetPaddingRight">
      <!-- oil distance -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.oilDistance?.fieldId, translationPath)"
        [value]="serviceInterval?.oilDistance?.value" [unit]="serviceInterval?.oilDistance?.unit">
      </app-description-value-cell>
      <!-- oil time -->
      <app-description-value-cell
        [fieldName]="mapService.mapFieldIdToPath(serviceInterval?.oilTime?.fieldId, translationPath)"
        [value]="serviceInterval?.oilTime?.value" [unit]="serviceInterval?.oilTime?.unit">
      </app-description-value-cell>
    </groupui-grid-col>

  </groupui-grid-row>
</app-icon-card>
